import React from 'react'
import gif from '../assets/jo.gif'
import { Link, StaticQuery } from 'gatsby'

class SurprisePage extends React.Component {
    
    render() {
        return (
            <StaticQuery
                query={graphql`
                    {allFile(filter: {name: {eq: "download"}}) {
                        edges {
                        node {
                            publicURL
                            name
                        }
                        }
                    }}`}

                render={(data) => (
                    <div class="giflayout">
                        <img src={gif}></img>
                        <a href={data.allFile.edges[0].node.publicURL} download class="button">Download the surprise</a>
                    </div>
                )}
            
            ></StaticQuery>
        )
    }
}

export default SurprisePage